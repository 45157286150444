<template>
  <validation-provider
    :rules="rules"
    :name="name"
    v-slot="validationContext"
  >
    <b-form-group
      :label="label"
      :label-for="id"
    >
      <v-select
        v-if="isVSelect"
        :aria-describedby="describedby"
        :state="getValidationState(validationContext)"
        @input="changeValue"
        :clearable="true"
        label="name"
        :placeholder="placeholder"
        :id="id" :name="name"
        v-model="values"
        class=""
        :options="options"
        :class="validationContext.errors[0]?'is-invalid':''"
      />
      <b-form-select 
      v-else
      :state="!showValidationFeedback && showValidationFeedbackmes ? getValidationState(validationContext) : null"
      :class="{ 'is-invalid': showValidationFeedback&& showValidationFeedbackmes && !validationContext.valid    }"
      :aria-describedby="describedby"
      :placeholder="placeholder"
      @input="changeValue"
      :id="id" :name="name"
      :value-field="valueField"
      :text-field="textField"
      v-model="values"
      class="bg-white"
      :options="options"
      ></b-form-select>
      <b-form-invalid-feedback :id="describedby" class="d-block" v-if="showValidationFeedbackmes">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { ref, computed } from '@vue/composition-api';
import vSelect from 'vue-select'
export default {
  components:{
    vSelect
  },
	props: {
    id: {
      type: String,
      required: true,
    },
		rules: {
      type: Object,
      required: true,
    },
    valueField:{
      type: String,
      default:'value'
    },
    textField:{
      type: String,
      default:'text'
    },
		name: {
      type: String,
      required: true,
    },
		label: {
      type: String,
      required: true,
    },
		modelValue: {
      type: [String, Number, null, Object],
      default: null
    },
    isVSelect: {
      default: false,
    },
		placeholder: {
      type: String,
      required: true,
    },
    describedby: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    getValue: {
      type: String,
      default: 'value'
    },
    showValidationFeedback:{
      type:Boolean,
      default:false
    },
    showValidationFeedbackmes:{
      type:Boolean,
      default:true
    }
  },
  setup(props, { emit }) {
		
    const getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    }

    const values =  props.modelValue

    const changeValue = (event) => {
      emit('input', event)
    }

    return {
      getValidationState,
      changeValue,
      values
    }
	}
}
</script>